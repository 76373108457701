import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import PartnerSupportModal from "./PartnerSupportModal";
import SidebarEntry from "./SidebarEntry";
import UserDropdown from "../Header/UserDropdown";
import certApp from "./cert-app.png";
import equityIcon from "./equity-icon.svg";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import partnerSupportIcon from "./partner-support.png";
import pearlHome from "./pearl-home.png";
import pearlLogo from "./pearl-logo.png";
import resourceCenter from "./resource-center.png";
import styles from "./SidebarContent.module.css";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import LoginButton from "@/components/AppraiserRegistration/LoginButton";
import { usePerms } from "@/hooks/use-perms";

const SidebarContent = (props) => {
  const { pathname } = useLocation();
  const [show, setShow] = useState(false);
  const { hasAppraiserPerm } = usePerms();
  const closeModal = () => setShow(false);
  const showModal = (event) => {
    event.preventDefault();
    setShow(true);
  };
  const resourceCenterUrl = hasAppraiserPerm
    ? process.env.REACT_APP_RESOURCES_URL
    : `${process.env.REACT_APP_CERT_APP_URL}/login?service=helpjuice`;

  return (
    <>
      <div className={styles["sidebar-header"]}>
        <Link to="/homes">
          <img className={styles.logo} src={pearlLogo} alt="logo" />
        </Link>
        <FontAwesomeIcon
          icon={faTimes}
          size="2x"
          className={styles.close}
          onClick={props.closeSidebar}
        />
      </div>
      <UserDropdown cssClasses="mb-3" mobileOnly={true} />
      <h4 className={styles.title}>PEARL CERTIFICATION</h4>
      <SidebarEntry
        text="Pearl Certification Home"
        url="https://pearlcertification.com"
        icon={pearlHome}
      />
      <SidebarEntry
        text="Resource Center"
        url={resourceCenterUrl}
        icon={resourceCenter}
      />
      <SidebarEntry
        text="Partner Support"
        url="#"
        icon={partnerSupportIcon}
        handleShow={showModal}
      />
      {pathname === "/appraiser-signup" && (
        <div className={"mx-4 mb-4"}>
          <LoginButton />
        </div>
      )}
      <PartnerSupportModal show={show} closeModal={closeModal} />

      <h4 className={styles.apps}>APPS</h4>
      <SidebarEntry
        text="Pearl Solar Equity Calculator"
        icon={equityIcon}
        active={true}
      />
      {!hasAppraiserPerm && (
        <SidebarEntry
          text="Certification App"
          url={process.env.REACT_APP_CERT_APP_URL}
          icon={certApp}
        />
      )}
    </>
  );
};

export default SidebarContent;
